import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import packageJson from '../package.json';
import Login from './Login';
import Main from './components/Main';
import { checkSessionStatus, doGetLogin, doLogout } from './redux/AuthActions';
import {
  doGetBackendVersion,
  doGetConfigVars,
  setConsoleOutput,
} from './redux/ContextAction';
import { doSetUsage, sessionExpired } from './redux/SessionActions';
import { doGetTranslations } from './redux/TranslationAction';
import { doGetLoginTranslations } from './redux/TranslationLoginAction';
import commandDef from './redux/cli/commandsDef.json';
import { callStatus } from './redux/constants';
import store from './redux/store';
import { importCliProvider } from './utils/dynamic-imports-sl-cli';

const CliProvider = importCliProvider();

const App = () => {
  const dispatch = useDispatch();
  const session = useSelector(({ session }) => session);
  const auth = useSelector(({ auth }) => auth);
  const context = useSelector(({ context }) => context);
  const translations = useSelector(({ translations }) => translations);

  let modalOpen = useRef(false);
  let hasEventListener = useRef(false);
  let forceLogout = useRef(false);
  let intervalCheck = useRef();
  let intervalUsage = useRef();

  useEffect(() => {
    if (!hasEventListener.current) {
      document.addEventListener('visibilitychange', async () => {
        const dataAuth = store.getState().auth;

        if (!document.hidden && dataAuth.status === callStatus.SUCCESS) {
          hasEventListener.current = true;

          if (!modalOpen.current) {
            const res = await checkSessionStatus();
            if (
              res?.data?.user &&
              !isEqual(res.data.user, dataAuth.data['new_user']) &&
              !isEqual(res.data.user, dataAuth.data['user'])
            ) {
              modalOpen.current = true;
              const messageContent = res.data.is_authenticated
                ? translations.data.login.another_user_logged_in
                : translations.data.login.user_logged_out;

              dispatch(sessionExpired(messageContent));
            }
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.removeItem('force_logout_timestamp');
  });

  useEffect(() => {
    if (auth.status === callStatus.PENDING) {
      dispatch(doGetLogin());
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (session.status === callStatus.SUCCESS) {
      const startIntervalCheck = () => {
        intervalCheck.current = setInterval(async () => {
          const res = await checkSessionStatus();
          const dataAuth = store.getState().auth;
          const dataSession = store.getState().auth;
          if (
            dataAuth.status === callStatus.SUCCESS &&
            dataSession.status === callStatus.SUCCESS &&
            res?.data?.user &&
            !isEqual(res.data.user, dataAuth.data['new_user']) &&
            !isEqual(res.data.user, dataAuth.data['user'])
          ) {
            modalOpen.current = true;
            const messageContent = res.data.is_authenticated
              ? translations.data.login.another_user_logged_in
              : translations.data.login.user_logged_out;

            dispatch(sessionExpired(messageContent));
          }
        }, 300000);
      };
      !intervalCheck.current && startIntervalCheck();

      const startIntervalUsage = () => {
        intervalUsage.current = setInterval(async () => {
          dispatch(doSetUsage());
        }, 150000);
      };
      !intervalUsage.current && startIntervalUsage();
    }

    if (
      session.status === callStatus.PENDING ||
      session.status === callStatus.EXPIRED ||
      session.status === callStatus.FAILED_GET
    ) {
      intervalCheck.current && clearInterval(intervalCheck.current);
      intervalCheck.current = null;
      intervalUsage.current && clearInterval(intervalUsage.current);
      intervalUsage.current = null;
    }
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    if (auth.status === callStatus.SUCCESS) {
      doGetTranslations(dispatch);
      dispatch(doSetUsage(true));

      if (
        !forceLogout.current &&
        context?.configVars?.SL_MAIN_FORCE_LOGOUT &&
        context?.configVars?.SL_MAIN_FORCE_LOGOUT > 0
      ) {
        forceLogout.current = true;
      }

      const forceLogoutTimestamp = localStorage.getItem(
        'force_logout_timestamp'
      );

      if (forceLogoutTimestamp) {
        const timeDiff = forceLogoutTimestamp
          ? Math.abs(Date.now() - forceLogoutTimestamp) / 1000
          : undefined;

        if (timeDiff && timeDiff > context?.configVars?.SL_MAIN_FORCE_LOGOUT) {
          localStorage.removeItem('force_logout_timestamp');

          dispatch(doLogout());
          forceLogout.current = false;
        }
      }
    }

    // eslint-disable-next-line
  }, [
    auth.status,
    context?.configVars?.SL_MAIN_FORCE_LOGOUT,
    dispatch,
    forceLogout,
  ]);

  useEffect(() => {
    doGetBackendVersion(dispatch);
    doGetLoginTranslations(dispatch);
    doGetConfigVars(dispatch);

    const handleTabClose = (event) => {
      if (forceLogout.current) {
        localStorage.setItem('force_logout_timestamp', Date.now());
      }
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
    // eslint-disable-next-line
  }, []);

  const getCommandDef = (command) => {
    return commandDef.commands.find(
      (commandDef) =>
        commandDef.name === command || commandDef.acronym === command
    );
  };

  const customParse = (command, dispatch = null) => {
    try {
      let commandDef = getCommandDef(command.name);

      if (!commandDef) {
        throw new Error(translations.data.cli.unrecognized_command);
      }

      let action = {};
      action.type = commandDef.name;
      action.params = {};

      const paramsText = Object.entries(command.params)
        .map(([key, value]) => {
          return isNaN(key) ? `${key}:${value}` : value;
        })
        .join(' ');
      action.commandText = `${command.name} ${paramsText}`;

      const params = command.params;

      commandDef.parameters.forEach((param, index) => {
        let value = params[param.name] || params[param.acronym];
        value = value ? value : params[index];

        if (value) {
          action.params[param.name] = value;
        } else if (param.mandatory) {
          throw new Error(
            `${translations.data.cli.parameter_required} (Parameter: ${param.name})`
          );
        }
      });

      return action;
    } catch (error) {
      dispatch
        ? dispatch(setConsoleOutput({ errors: error }))
        : console.log(error);

      throw error;
    }
  };

  if (
    auth.status === callStatus.PENDING ||
    auth.status === callStatus.FAILED ||
    auth.status === callStatus.FAILED_GET ||
    auth.status === callStatus.IN_PROCESS
  ) {
    return (
      <div style={{ height: '100vh' }}>
        <Login modalOpen={modalOpen} />
      </div>
    );
  }

  return (
    <CliProvider
      commands={commandDef}
      translations={translations.data.cli}
      version={{
        frontend: packageJson.version,
        backend: context.backendVersion,
      }}
      customParse={customParse}
    >
      <Main forceLogoutRef={forceLogout} modalOpen={modalOpen} />
    </CliProvider>
  );
};

export default App;
