import packageJson from '../../package.json';
import { authApi, systemApi } from '../utils/api';
import { buildAppFromHash, checkLocalSettings } from './MainActions';
import store from './store';
import { SESSION } from './types/SessionActionTypes';

export const doLogin = (data, modalOpen) => (dispatch) => {
  const user = store.getState().auth.data;
  dispatch(sessionInProgress());
  return authApi
    .post('/login/', { usr: data.username, pwd: data.password })
    .then((response) => {
      const username = user.user ? user.user : user.new_user;
      if (username === response.data.new_user) {
        dispatch(sessionResolved(response.data));
      } else {
        dispatch(sessionRefresh(response.data));
      }
      modalOpen.current = false;
    })
    .catch((error) => {
      dispatch(sessionFailed(error));
    });
};

export const doSetUsage =
  (updateSession = false) =>
  async (dispatch) => {
    const authData = store.getState().auth.data;

    let app = 'SMARTLIS';
    try {
      await checkLocalSettings();
      if (window.slConfig.app) {
        app = window.slConfig.app;
      } else {
        app = buildAppFromHash(window.location.hash);
      }
    } catch (error) {
      app = buildAppFromHash(window.location.hash);
    }

    return systemApi
      .post('/update_usage/', {
        app: app === '' ? 'SMARTLIS_NG' : app,
        timeout_secs: 135,
        work_sess_user: authData.user ? authData.user : authData.new_user,
        client_version: packageJson.version,
      })
      .then(() => {
        updateSession && dispatch(sessionResolved(authData));
      })
      .catch((error) => {
        dispatch(sessionFailed(error));
      });
  };

export const doExpired =
  (message = null) =>
  (dispatch) => {
    return authApi
      .get('/logout/')
      .then(async (response) => {
        dispatch(sessionExpired(message));
      })
      .catch((error) => {
        dispatch(sessionFailed(error));
      });
  };

export const sessionPending = () => {
  return {
    type: SESSION.IDLE,
  };
};
const sessionInProgress = () => {
  return {
    type: SESSION.IN_PROGRESS,
  };
};
const sessionFailed = (error) => {
  return {
    type: SESSION.REJECTED,
    payload: error.message,
  };
};

export const sessionExpired = (data) => {
  return {
    type: SESSION.EXPIRED,
    payload: data,
  };
};
export const sessionRefresh = (data) => {
  return {
    type: SESSION.REFRESH,
    payload: data,
  };
};
export const sessionResolved = (data) => {
  return {
    type: SESSION.RESOLVED,
    payload: data,
  };
};
